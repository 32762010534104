import React from 'react';
import { HeartHalf, PersonCircle, Twitch, TwitterX, Youtube } from 'react-bootstrap-icons';

const currentYear = new Date().getFullYear();
const Footer: React.FC = (props) => {
    return (
        <footer>
            <div className="container">
                <ul>
                    <li>
                        <a target="_blank" href="https://www.colinhorn.co.uk">
                            <PersonCircle size={25} />
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.twitch.tv/C0LINHORN">
                        <Twitch size={25} />
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.youtube.com/colinhorn">
                            <Youtube size={29} />
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.twitter.com/C0LINHORN">
                            <TwitterX size={25} />
                        </a>
                    </li>
                    <li>
                        <a href="https://shop.colinhorn.co.uk/pages/donate" target='_blank'>
                        <HeartHalf size={25} /></a>
                    </li>
                </ul>
                <span>
                    &copy; Copyright Colin Horn {currentYear}
                </span>
            </div>
        </footer>
    )
}

export default Footer;